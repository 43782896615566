import * as React from "react";
import { SVGProps } from "react";

const XingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.188 4.74c-.245 0-.451.073-.555.216-.107.148-.09.338.023.53l2.75 4.052c.005.008.005.013 0 .02l-4.321 6.49c-.113.191-.107.383 0 .531.103.142.287.236.531.236h4.068c.608 0 .9-.35 1.109-.669l4.39-6.608-2.796-4.15c-.202-.306-.508-.649-1.132-.649H2.188ZM19.274 0c-.608 0-.871.326-1.09.66l-9.05 13.658 5.78 9.023c.2.307.512.659 1.135.659h4.063c.245 0 .436-.079.54-.221.108-.148.105-.343-.009-.535l-5.733-8.915a.018.018 0 0 1 0-.023L23.915.756c.113-.192.115-.387.008-.535C23.82.08 23.627 0 23.383 0h-4.11Z"
      fill="#fff"
    />
  </svg>
);

export default XingIcon;
