import * as React from "react"
import { SVGProps } from "react"

const IdeasLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={129}
        height={128}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M64.46 79.69c8.92 0 16.15-7.23 16.15-16.15 0-8.92-7.23-16.151-16.15-16.151-8.92 0-16.151 7.23-16.151 16.15 0 8.92 7.23 16.152 16.15 16.152Z"
            fill="#7D8FC7"
        />
        <path
            d="M88.905 39.092c-13.733-13.736-36.087-13.738-49.824 0-9.25 9.25-11.347 17.65-13.375 25.776-1.946 7.8-3.786 15.168-11.863 23.247a1.594 1.594 0 0 0 0 2.256l1.137 1.137-4.773 4.773-.004.002-.003.005-8.378 8.378a6.237 6.237 0 0 0 0 8.81l12.7 12.698A6.185 6.185 0 0 0 18.93 128a6.188 6.188 0 0 0 4.405-1.825l13.158-13.156 1.135 1.135a1.596 1.596 0 0 0 2.256 0c8.078-8.078 15.446-9.917 23.248-11.863 8.126-2.029 16.527-4.124 25.775-13.377 13.737-13.734 13.737-36.084-.001-49.822Zm-67.83 84.828c-1.148 1.145-3.148 1.147-4.295-.002l-12.7-12.697a3.043 3.043 0 0 1 0-4.297l1.541-1.541 8.5 8.497c.312.311.719.466 1.128.466a1.596 1.596 0 0 0 1.128-2.725l-8.5-8.498 3.454-3.453 8.498 8.499c.312.312.72.467 1.128.467a1.592 1.592 0 0 0 1.129-2.723l-8.5-8.5 3.649-3.648 16.996 16.997-13.155 13.158Zm65.572-37.26c-8.623 8.625-16.234 10.524-24.291 12.534-7.532 1.881-15.31 3.821-23.585 11.594l-9.652-9.654 19.75-19.75a1.596 1.596 0 1 0-2.258-2.257L26.864 98.88l-9.652-9.653c7.771-8.273 9.712-16.051 11.592-23.585 2.012-8.058 3.91-15.667 12.536-24.291 12.492-12.493 32.817-12.493 45.308 0 12.493 12.491 12.493 32.818-.001 45.31Z"
            fill="#fff"
        />
        <path
            d="M94.971 95.652c-.407 0-.818-.155-1.128-.465L81.36 82.705a1.597 1.597 0 0 1 2.257-2.26L96.1 92.928a1.597 1.597 0 0 1-1.129 2.725ZM46.403 47.085c-.41 0-.818-.156-1.129-.467L32.792 34.135a1.596 1.596 0 1 1 2.258-2.258L47.532 44.36a1.596 1.596 0 0 1-1.13 2.725ZM107.615 65.128H89.962a1.597 1.597 0 0 1 0-3.19h17.653a1.596 1.596 0 0 1 0 3.19ZM64.445 39.612a1.596 1.596 0 0 1-1.595-1.596V20.362a1.596 1.596 0 0 1 3.191 0v17.653c0 .882-.714 1.597-1.596 1.597ZM82.489 47.085a1.597 1.597 0 0 1-1.128-2.725l12.482-12.483a1.597 1.597 0 0 1 2.257 2.258L83.617 46.618c-.312.31-.721.467-1.128.467ZM27.84 21.5h-2.234v-2.233a1.596 1.596 0 0 0-3.191 0V21.5H20.18a1.596 1.596 0 0 0 0 3.192h2.234v2.233a1.596 1.596 0 0 0 3.191 0v-2.233h2.234a1.596 1.596 0 0 0 0-3.192ZM109.746 20.486h-2.234v-2.234a1.596 1.596 0 0 0-3.191 0v2.234h-2.235a1.597 1.597 0 0 0 0 3.193h2.235v2.234a1.595 1.595 0 1 0 3.191 0v-2.234h2.234a1.597 1.597 0 0 0 0-3.193ZM108.855 102.516h-2.235v-2.234a1.596 1.596 0 0 0-3.191 0v2.234h-2.234a1.597 1.597 0 0 0 0 3.191h2.234v2.234a1.597 1.597 0 0 0 3.191 0v-2.234h2.235a1.596 1.596 0 0 0 0-3.191ZM64.626 11.09a5.551 5.551 0 0 1-5.544-5.545A5.551 5.551 0 0 1 64.626 0a5.551 5.551 0 0 1 5.545 5.545 5.551 5.551 0 0 1-5.545 5.544Zm0-7.899a2.354 2.354 0 0 0-2.352 2.352 2.355 2.355 0 0 0 2.352 2.352 2.356 2.356 0 0 0 2.354-2.352 2.356 2.356 0 0 0-2.354-2.352ZM122.672 69.133a5.55 5.55 0 0 1-5.545-5.545 5.55 5.55 0 0 1 5.545-5.544 5.55 5.55 0 0 1 5.544 5.544 5.55 5.55 0 0 1-5.544 5.545Zm0-7.897a2.356 2.356 0 0 0-2.354 2.352 2.356 2.356 0 0 0 2.354 2.354 2.357 2.357 0 0 0 2.353-2.354 2.356 2.356 0 0 0-2.353-2.352Z"
            fill="#7D8FC7"
        />
    </svg>
)

export default IdeasLogo
