import * as React from "react";
import { SVGProps } from "react";

const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.87 19.882.26 22.493a.88.88 0 0 0 0 1.248.88.88 0 0 0 1.247 0l2.61-2.61a.88.88 0 0 0 0-1.249.88.88 0 0 0-1.247 0Z"
      fill="#fff"
    />
    <path
      d="M23.294 7.923 16.078.705a2.445 2.445 0 0 0-3.436 0L2.294 11.052c-.94.94-.94 2.496 0 3.436l.576.576-2.61 2.61a.88.88 0 0 0 0 1.249.88.88 0 0 0 1.247 0l2.63-2.611 3.57 3.57-2.61 2.611a.88.88 0 0 0 0 1.248.88.88 0 0 0 1.247 0l2.611-2.61.576.575c.94.94 2.495.94 3.436 0L23.314 11.36a2.463 2.463 0 0 0-.02-3.436ZM13.121 2.721l-1.095 7.717-7.716 1.094 8.81-8.81Zm8.926 7.39L11.7 20.459a.672.672 0 0 1-.96 0l-7.064-7.064 9.233-1.324a.83.83 0 0 0 .5-.25.95.95 0 0 0 .25-.5l1.324-9.232 7.064 7.064a.672.672 0 0 1 0 .96Z"
      fill="#fff"
    />
  </svg>
);

export default MailIcon;
