import * as React from "react"
import { SVGProps } from "react"

const DevLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={104}
        height={128}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M51.986 74.834c20.665 0 37.417-16.752 37.417-37.417C89.403 16.752 72.65 0 51.986 0 31.32 0 14.569 16.752 14.569 37.417c0 20.665 16.752 37.417 37.417 37.417ZM66.154 112.711c-.804 0-1.455-.65-1.455-1.455V79.829a1.454 1.454 0 1 1 2.91 0v31.427a1.455 1.455 0 0 1-1.455 1.455ZM36.626 109.733c-.803 0-1.455-.65-1.455-1.455v-25.95a1.455 1.455 0 1 1 2.91 0v25.95c0 .805-.651 1.455-1.455 1.455ZM81.23 96.999c-.804 0-1.456-.65-1.456-1.455V73.91a1.454 1.454 0 1 1 2.91 0v21.633c0 .805-.65 1.455-1.455 1.455ZM21.552 92.476c-.803 0-1.455-.65-1.455-1.455v-17.69a1.455 1.455 0 1 1 2.91 0v17.69a1.456 1.456 0 0 1-1.455 1.455ZM68.21 123.94l1.578-1.578a1.454 1.454 0 1 0-2.056-2.056l-1.578 1.577-1.577-1.577a1.454 1.454 0 1 0-2.057 2.056l1.577 1.578-1.577 1.577a1.452 1.452 0 0 0 0 2.056c.285.285.656.427 1.028.427.373 0 .744-.142 1.029-.427l1.577-1.577 1.577 1.576a1.45 1.45 0 0 0 2.057 0 1.453 1.453 0 0 0 0-2.057l-1.578-1.575ZM23.609 103.777l1.577-1.577a1.453 1.453 0 0 0 0-2.057 1.453 1.453 0 0 0-2.057 0l-1.577 1.577-1.577-1.577a1.453 1.453 0 0 0-2.057 0 1.453 1.453 0 0 0 0 2.057l1.578 1.577-1.578 1.577a1.453 1.453 0 0 0 1.029 2.483 1.45 1.45 0 0 0 1.028-.426l1.577-1.578 1.577 1.578a1.45 1.45 0 0 0 2.057 0 1.453 1.453 0 0 0 0-2.057l-1.577-1.577ZM36.626 127.553a5.258 5.258 0 0 1-5.252-5.252 5.259 5.259 0 0 1 5.252-5.252 5.26 5.26 0 0 1 5.254 5.252 5.259 5.259 0 0 1-5.254 5.252Zm0-7.596a2.346 2.346 0 0 0-2.344 2.344 2.346 2.346 0 0 0 2.344 2.344 2.345 2.345 0 0 0 0-4.688ZM81.23 113.556a5.26 5.26 0 0 1-5.254-5.252 5.259 5.259 0 0 1 5.253-5.252 5.258 5.258 0 0 1 5.252 5.252 5.257 5.257 0 0 1-5.252 5.252Zm0-7.597a2.347 2.347 0 0 0-2.346 2.343 2.347 2.347 0 0 0 2.345 2.344 2.346 2.346 0 0 0 2.344-2.344 2.346 2.346 0 0 0-2.344-2.343Z"
            fill="#7D8FC7"
        />
        <path
            d="M29.663 42.32V38.65l13.194-6.078v4.453L35.025 40.5l7.832 3.445v4.452l-13.194-6.077ZM49.03 52.168h-3.964l9.262-27.949h3.965L49.03 52.168ZM60.416 48.398v-4.452l7.832-3.445-7.832-3.477V32.57L73.61 38.65v3.672l-13.194 6.077Z"
            fill="#fff"
        />
        <path
            d="M102.52 9.375H1.455C.65 9.375 0 10.027 0 10.83v56.695c0 .803.651 1.455 1.455 1.455h49.077v25.966c-2.185.636-3.798 2.634-3.798 5.024a5.26 5.26 0 0 0 5.253 5.252 5.259 5.259 0 0 0 5.252-5.252c0-2.39-1.612-4.388-3.798-5.023V68.98h49.077c.804 0 1.455-.65 1.455-1.455V10.83a1.452 1.452 0 0 0-1.453-1.455ZM54.331 99.971a2.346 2.346 0 0 1-2.344 2.344 2.346 2.346 0 0 1-2.343-2.344 2.348 2.348 0 0 1 2.343-2.345 2.348 2.348 0 0 1 2.344 2.345Zm46.734-33.901H2.91V12.284h98.155V66.07Z"
            fill="#fff"
        />
    </svg>
)

export default DevLogo
