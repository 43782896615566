import * as React from "react"
import { SVGProps } from "react"

const UxLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={128}
        height={128}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M65.77 80.885c10.086 0 18.263-8.177 18.263-18.263 0-10.086-8.177-18.263-18.263-18.263-10.086 0-18.263 8.177-18.263 18.263 0 10.086 8.177 18.263 18.263 18.263Z"
            fill="#7D8FC7"
        />
        <path
            d="M63.987 128c-10.175 0-20.317-2.443-29.611-7.299C19.23 112.785 8.073 99.446 2.962 83.139-2.151 66.832-.608 49.51 7.308 34.365 19.998 10.082 46.71-3.423 73.768.751a1.462 1.462 0 0 1-.445 2.888C47.49-.347 22.008 12.544 9.897 35.72 2.343 50.172.87 66.702 5.749 82.264c4.88 15.562 15.527 28.292 29.98 35.846 16.35 8.545 35.458 9.263 52.421 1.971.735-.314 1.6.024 1.919.767a1.459 1.459 0 0 1-.767 1.918A64.054 64.054 0 0 1 63.987 128ZM109.539 112.57h-3.499v-3.497a1.46 1.46 0 1 0-2.921 0v3.497h-3.497a1.46 1.46 0 0 0-1.46 1.461 1.46 1.46 0 0 0 1.46 1.461h3.497v3.498a1.46 1.46 0 1 0 2.921 0v-3.498h3.499a1.46 1.46 0 1 0 0-2.922Z"
            fill="#7D8FC7"
        />
        <path
            d="M127.101 19.917 114.083 4.375a1.458 1.458 0 0 0-2.058-.182L68.001 41.068a1.44 1.44 0 0 0-.365.468c-.001.004-.006.006-.007.01L56.676 63.943a1.466 1.466 0 0 0 .192 1.582 1.464 1.464 0 0 0 1.522.466l23.972-6.856c.013-.005.023-.015.035-.019.178-.055.349-.138.5-.266l44.023-36.875c.617-.518.698-1.44.181-2.058ZM69.264 44.85l10.13 12.093-18.652 5.335 8.523-17.428Zm12.876 10.82-11.142-13.3 41.784-35 11.14 13.301-41.782 35ZM87.322 71.92H62.334a1.46 1.46 0 1 1 0-2.92H87.317c5.936 0 11.508-3.682 14.919-9.86 3.929-7.122 10.457-11.367 17.469-11.367h.065l5.863.022a1.461 1.461 0 0 1-.006 2.922h-.006l-5.862-.023c-5.961-.053-11.548 3.663-14.966 9.857-3.933 7.123-10.46 11.37-17.47 11.37Z"
            fill="#fff"
        />
        <path
            d="M62.364 103.89c-15.309.002-29.69-8.843-36.354-23.586-4.98-11.02-4.736-23.553.67-34.385a1.459 1.459 0 0 1 1.96-.654 1.46 1.46 0 0 1 .654 1.96c-5.012 10.042-5.238 21.66-.621 31.875 7.916 17.513 27.578 26.052 45.748 19.857a38.024 38.024 0 0 0 3.35-1.326 1.46 1.46 0 1 1 1.203 2.663 41.363 41.363 0 0 1-3.61 1.428 40.255 40.255 0 0 1-13 2.168ZM37.09 40.35a5.987 5.987 0 0 1-2.313-.467 5.97 5.97 0 0 1-3.243-3.26 5.965 5.965 0 0 1 .013-4.597 6.01 6.01 0 0 1 7.856-3.23 6.013 6.013 0 0 1 3.23 7.856 5.97 5.97 0 0 1-3.26 3.243 5.972 5.972 0 0 1-2.283.455Zm-2.846-7.198a3.089 3.089 0 0 0 1.66 4.035c.76.318 1.598.32 2.36.006a3.061 3.061 0 0 0 1.674-1.666 3.09 3.09 0 0 0-1.66-4.035 3.093 3.093 0 0 0-4.034 1.66Z"
            fill="#7D8FC7"
        />
    </svg>
)

export default UxLogo