import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import khaled_bitmoji from "./assets/khaled-bitmoji.png";
import khaled_profile_picture from "./assets/khaled-profile-picture.png";
import IdeasLogo from "./assets/IdeasLogo";
import UxLogo from "./assets/UxLogo";
import DevLogo from "./assets/DevLogo";
import DataScienceLogo from "./assets/DataScienceLogo";
import TechnologiesLogos from "./assets/TechnologiesLogos";
import MailIcon from "./assets/MailIcon";
import LinkedInIcon from "./assets/LinkedInIcon";
import XingIcon from "./assets/XingIcon";
import InstagramIcon from "./assets/InstagramIcon";

function App() {
  const contactRef = useRef<HTMLInputElement>(null);
  const [showImprint, setShowImprint] = useState(false);

  const [technologyLogos, setTechnologyLogos] = useState(
    <TechnologiesLogos id="technologies-logo" className="horizontal-center" />
  );

  const handleResize = () => {
    setTechnologyLogos(
      <TechnologiesLogos
        key={"technologies-logo" + Date().toLocaleString()}
        id="technologies-logo"
      />
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <div className="App">
      <div id="intro" className="horizontal-center">
        <p className="adi-cn size-20">HEY👋🏽 MEIN NAME IST</p>
        <div id="bitmoji-name-container">
          <img id="khaled-bitmoji" src={khaled_bitmoji} />
          <div id="name-rect-bg">
            <p id="name" className="adi-bold blue caps size-40 margin-0">
              Khaled Osmani.
            </p>
          </div>
        </div>

        <p className="adi-bold caps size-32">
          ICH KREIRE HOCHWERTIGE SOFTWARE.
        </p>
        <p style={{ marginTop: "-20px", marginBottom: "30px" }}>
          Ich bin Software Engineer mit Fokus auf Frontend-Entwicklung,
          UX-Design & Data Science. Das Ziel meiner Arbeit ist ein digitales
          Erlebnis für meine Kunden und vor allem für die User zu schaffen.
        </p>
        <button
          id="contact-button"
          type="button"
          onClick={() =>
            contactRef?.current?.scrollIntoView({ behavior: "smooth" })
          }
        >
          Jetzt kontaktieren
        </button>
      </div>

      <p className="adi-bold caps size-32 section-title">Services</p>

      <div id="services-container" className="max-width-765 horizontal-center">
        <div>
          <IdeasLogo />
          <p className="adi-bold caps size-20">Ideenfindung & Beratung</p>
        </div>

        <div>
          <UxLogo />
          <p className="adi-bold caps size-20">UX/UI Design & Prototyping</p>
        </div>

        <div>
          <DevLogo />
          <p className="adi-bold caps size-20">Software-Entwicklung</p>
        </div>

        <div id="data-science">
          <DataScienceLogo />
          <p className="adi-bold caps size-20">Data Science</p>
        </div>
      </div>

      <p className="adi-bold caps size-32 section-title">Über mich</p>

      <img src={khaled_profile_picture} />

      <p className="max-width-765 horizontal-center justify">
        Hi! Ich heiße Khaled Osmani und entwickle Software seitdem ich im Alter
        von 12 Jahren mein erstes Buch zum Thema Programmieren geschenkt
        bekommen habe. Damals habe ich mit der Programmiersprache Visual Basic
        angefangen. Begeistert von der Software-Entwicklung habe ich nach dem
        Abitur Informatik studiert (Abschluss Master of Science) und arbeite
        seit 2012 bei der Volkswagen AG. Dort habe ich bereits verschiedene
        Positionen durchlaufen. Vom Projektmanager, über Data Scientist bis zum
        Software Engineer. Ich würde mich selbst als flexiblen & vielseitig
        einsetzbaren Generalisten bezeichnen, der sich ständig weiterbildet und
        gerne neue Technologien erlernt. Aktuell bin ich Software Engineer mit
        Fokus auf Frontend-Entwicklung & UX-Design. Ich arbeite dabei mit Liebe
        zum Detail (auf das Pixel genau).
      </p>

      <p className="left max-width-765 horizontal-center">
        2008 - 2011 | Hochschule Darmstadt.{" "}
        <span style={{ fontWeight: "bold" }}>
          Bachelor of Science - Informatik
        </span>
      </p>

      <p className="left max-width-765 horizontal-center">
        2011 - 2013 | Hochschule Darmstadt & James Cook University Australia.{" "}
        <span style={{ fontWeight: "bold" }}>
          Master of Science - Informatik
        </span>
      </p>

      <p className="left max-width-765 horizontal-center">
        2012 - heute | Software Engineer @Volkswagen AG
      </p>

      <p className="adi-bold caps size-32 section-title">Technologien</p>

      <div className="horizontal-center max-width-765">{technologyLogos}</div>

      <p className="adi-bold caps size-32 section-title">Projekte</p>

      <div className="left max-width-765 horizontal-center">
        <p className="adi-bold size-14">2013 - 2014 | Teilprojektleitung</p>
        <p className="project-description">
          Business Intelligence Projekt in der Fabriksteuerung. Steuerung von
          externen Entwicklern. Aufbau eines Teams in Indien.
        </p>
        <p className="adi-bold size-14">2014 - 2015 | Projektleitung </p>
        <p className="project-description">
          Business Intelligence Projekt in der Konzern Produktion. Steuerung von
          externen Entwicklern. Aufbau eines Teams in Indien. Umstellung auf
          SCRUM.
        </p>
        <p className="adi-bold size-14">2015 - 2016 | Data Scientist </p>
        <p className="project-description">
          Entwicklung von PoCs in der Produktion zum Thema Big Data. [Python,
          Hadoop, Power Bi, Elasticsearch]
        </p>
        <p className="adi-bold size-14">
          2016 - 2017 | Data Scientist & Software Engineer
        </p>
        <p className="project-description">
          Wertstromoptimierung in der Produktion. [Python, C#, MS SQL]
        </p>
        <p className="adi-bold size-14">
          2017 - 2018 | Data Scientist & Software Engineer
        </p>
        <p className="project-description">
          Optimierung der Vorplanungsanalyse in der Logistik. [Power Bi,
          Angular, JavaScript, CSS]
        </p>
        <p className="adi-bold size-14">
          2018 | Data Scientist & Software Engineer
        </p>
        <p className="project-description">
          Automatisierte Gurterkennung beim Pickingprozess in der Produktion.
          [Angular, JavaScript, CSS, Python, Tensorflow]
        </p>
        <p className="adi-bold size-14">
          2019 - 2020 | Data Scientist & Software Engineer
        </p>
        <p className="project-description">
          Analyse in der Statorfertigung für Elektromotoren. [React, JavaScript,
          CSS, Java, Docker, Sketch, Invision]
        </p>
        <p className="adi-bold size-14">
          2020 - 2022| UX/UI Designer & Software Engineer
        </p>
        <p className="project-description">
          DSGVO-konformes Kontaktbuch für Disponenten. [React, TypeScript, CSS,
          Kotlin, Docker, AWS, Figma]
        </p>
        <p className="adi-bold size-14">
          2022 - heute | UX/UI Designer & Software Engineer
        </p>
        <p className="project-description">
          Virtuelle Entwicklungsumgebung in der Cloud. [React, TypeScript, CSS,
          Docker, Figma]
        </p>
      </div>

      <p className="adi-bold caps size-32 section-title">Kontakt</p>
      <div
        id="contact-container"
        ref={contactRef}
        className="horizontal-center"
      >
        <a href="mailto:osmani.khaled@gmail.com">
          <MailIcon />
        </a>

        <a
          href="https://www.linkedin.com/in/khaled-osmani/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon />
        </a>

        <a
          href="https://www.xing.com/profile/Khaled_Osmani/cv"
          target="_blank"
          rel="noreferrer"
        >
          <XingIcon />
        </a>

        <a
          href="https://www.instagram.com/khld788/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
      </div>

      <p
        className="horizontal-center"
        style={{
          cursor: "pointer",
        }}
        onClick={() => setShowImprint(true)}
      >
        Impressum
      </p>

      {showImprint && (
        <div id="imprint-popup">
          <div id="imprint-popup-content">
            <p
              style={{
                textAlign: "right",
                marginRight: "15px",
                cursor: "pointer",
              }}
              onClick={() => setShowImprint(false)}
            >
              Schließen
            </p>
            <p className="adi-bold">Impressum</p>
            <p>Angaben gem. § 5 TMG:</p>
            <p>
              Khaled Osmani <br /> Berliner Ring 22 <br /> 31241 Ilsede
            </p>

            <p>Kontaktaufnahme:</p>
            <p>E-Mail: osmani.khaled@gmail.com</p>

            <p className="adi-bold">Haftungsausschluss – Disclaimer:</p>
            <p>Haftung für Inhalte</p>
            <p>
              Alle Inhalte unseres Internetauftritts wurden mit größter Sorgfalt
              und nach bestem Gewissen erstellt. Für die Richtigkeit,
              Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
              Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
              TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
              Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
              Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
              gespeicherte fremde Informationen zu überwachen oder nach
              Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
              hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
              Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
              hiervon unberührt.
              <br />
              Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntniserlangung einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von den o.g. Rechtsverletzungen werden wir diese
              Inhalte unverzüglich entfernen.
            </p>

            <p>Haftungsbeschränkung für externe Links</p>
            <p>
              Unsere Webseite enthält Links auf externe Webseiten Dritter. Auf
              die Inhalte dieser direkt oder indirekt verlinkten Webseiten haben
              wir keinen Einfluss. Daher können wir für die „externen Links“
              auch keine Gewähr auf Richtigkeit der Inhalte übernehmen. Für die
              Inhalte der externen Links sind die jeweilige Anbieter oder
              Betreiber (Urheber) der Seiten verantwortlich.
              <br />
              Die externen Links wurden zum Zeitpunkt der Linksetzung auf
              eventuelle Rechtsverstöße überprüft und waren im Zeitpunkt der
              Linksetzung frei von rechtswidrigen Inhalten. Eine ständige
              inhaltliche Überprüfung der externen Links ist ohne konkrete
              Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei direkten
              oder indirekten Verlinkungen auf die Webseiten Dritter, die
              außerhalb unseres Verantwortungsbereichs liegen, würde eine
              Haftungsverpflichtung ausschließlich in dem Fall nur bestehen,
              wenn wir von den Inhalten Kenntnis erlangen und es uns technisch
              möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
              Inhalte zu verhindern.
              <br />
              Diese Haftungsausschlusserklärung gilt auch innerhalb des eigenen
              Internetauftrittes „Name Ihrer Domain“ gesetzten Links und
              Verweise von Fragestellern, Blogeinträgern, Gästen des
              Diskussionsforums. Für illegale, fehlerhafte oder unvollständige
              Inhalte und insbesondere für Schäden, die aus der Nutzung oder
              Nichtnutzung solcherart dargestellten Informationen entstehen,
              haftet allein der Diensteanbieter der Seite, auf welche verwiesen
              wurde, nicht derjenige, der über Links auf die jeweilige
              Veröffentlichung lediglich verweist.
              <br />
              Werden uns Rechtsverletzungen bekannt, werden die externen Links
              durch uns unverzüglich entfernt.
            </p>

            <p>Urheberrecht</p>
            <p>
              Die auf unserer Webseite veröffentlichen Inhalte und Werke
              unterliegen dem deutschen Urheberrecht
              (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf) .
              Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung des geistigen Eigentums in ideeller und materieller
              Sicht des Urhebers außerhalb der Grenzen des Urheberrechtes
              bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen
              Urhebers i.S.d. Urhebergesetzes
              (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf ).
              Downloads und Kopien dieser Seite sind nur für den privaten und
              nicht kommerziellen Gebrauch erlaubt. Sind die Inhalte auf unserer
              Webseite nicht von uns erstellt wurden, sind die Urheberrechte
              Dritter zu beachten. Die Inhalte Dritter werden als solche
              kenntlich gemacht. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte unverzüglich entfernen.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
