import * as React from "react";
import { SVGProps } from "react";

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.001 2.162c3.205 0 3.584.012 4.85.07 1.17.054 1.805.249 2.228.413.56.218.96.478 1.38.898.42.42.68.82.897 1.38.164.423.36 1.058.413 2.228.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.053 1.17-.249 1.805-.413 2.228-.218.56-.478.96-.898 1.38-.42.42-.82.68-1.38.898-.422.164-1.058.36-2.228.413-1.265.058-1.644.07-4.849.07-3.204 0-3.583-.012-4.849-.07-1.17-.053-1.805-.249-2.228-.413a3.718 3.718 0 0 1-1.38-.898c-.42-.42-.68-.82-.897-1.38-.165-.423-.36-1.058-.413-2.228-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.053-1.17.248-1.805.413-2.228.217-.56.478-.96.897-1.38.42-.42.82-.68 1.38-.898.423-.164 1.058-.36 2.228-.413 1.266-.058 1.645-.07 4.85-.07Zm0-2.162C8.742 0 8.334.014 7.054.072 5.777.131 4.904.333 4.14.63a5.882 5.882 0 0 0-2.125 1.384A5.882 5.882 0 0 0 .63 4.14c-.296.763-.5 1.635-.557 2.912C.015 8.332 0 8.741 0 12c0 3.26.014 3.668.073 4.948.058 1.277.26 2.15.557 2.912.307.79.717 1.459 1.385 2.126A5.88 5.88 0 0 0 4.14 23.37c.763.297 1.636.5 2.913.558C8.334 23.986 8.742 24 12 24c3.26 0 3.668-.014 4.948-.072 1.277-.059 2.15-.261 2.913-.558a5.881 5.881 0 0 0 2.125-1.384 5.883 5.883 0 0 0 1.385-2.126c.296-.763.499-1.635.557-2.912.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.668-.073-4.948-.058-1.277-.26-2.15-.557-2.912a5.883 5.883 0 0 0-1.385-2.126A5.883 5.883 0 0 0 19.862.63c-.763-.297-1.636-.5-2.913-.558C15.669.014 15.26 0 12.001 0Zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324Zm0 10.162a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm7.846-10.406a1.44 1.44 0 1 1-2.88 0 1.44 1.44 0 0 1 2.88 0Z"
      fill="#fff"
    />
  </svg>
);

export default InstagramIcon;
