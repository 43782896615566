import * as React from "react";
import { SVGProps } from "react";

const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.452 20.45h-3.556v-5.57c0-1.328-.024-3.037-1.85-3.037-1.852 0-2.135 1.447-2.135 2.94v5.666H9.355V8.997h3.413v1.565h.048a3.746 3.746 0 0 1 3.369-1.85c3.604 0 4.268 2.37 4.268 5.455l-.001 6.282ZM5.342 7.43a2.074 2.074 0 0 1-2.064-2.063c0-1.132.932-2.064 2.064-2.064 1.132 0 2.063.931 2.063 2.063a2.074 2.074 0 0 1-2.063 2.064ZM7.12 20.45H3.56V8.997h3.56v11.452ZM22.225.002H1.773a1.76 1.76 0 0 0-1.77 1.73v20.536A1.76 1.76 0 0 0 1.772 24h20.452a1.764 1.764 0 0 0 1.777-1.732V1.73A1.763 1.763 0 0 0 22.225 0v.002Z"
      fill="#fff"
    />
  </svg>
);

export default LinkedInIcon;
