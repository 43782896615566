import * as React from "react"
import { SVGProps } from "react"

const DataScienceLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={130}
        height={128}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M72.164 128c16.756 0 30.339-13.583 30.339-30.339 0-16.755-13.583-30.338-30.339-30.338-16.755 0-30.338 13.583-30.338 30.338 0 16.756 13.583 30.339 30.338 30.339Z"
            fill="#7D8FC7"
        />
        <path
            d="m129.358 12.886-9.219-2.548.837 3.222-88.26 22.91a1.452 1.452 0 1 0 .732 2.813l88.258-22.912.836 3.223 6.816-6.708ZM108.186 111.028H97.097a4.503 4.503 0 0 1-4.497-4.499v-49.73a4.5 4.5 0 0 1 3.368-4.356l11.089-2.878a4.459 4.459 0 0 1 3.882.796 4.472 4.472 0 0 1 1.747 3.559v52.609a4.505 4.505 0 0 1-4.5 4.499Zm.005-58.702c-.136 0-.271.017-.404.051l-11.09 2.88a1.593 1.593 0 0 0-1.19 1.54v49.731c0 .878.714 1.593 1.59 1.593h11.089c.878 0 1.593-.715 1.593-1.593V53.92c0-.498-.226-.956-.62-1.26a1.568 1.568 0 0 0-.968-.334ZM77.702 111.028H66.614a4.504 4.504 0 0 1-4.5-4.499V64.712a4.5 4.5 0 0 1 3.37-4.355l11.089-2.879a4.46 4.46 0 0 1 3.882.797 4.47 4.47 0 0 1 1.746 3.558v44.696a4.506 4.506 0 0 1-4.499 4.499Zm.003-50.789a1.61 1.61 0 0 0-.402.052l-11.089 2.88a1.594 1.594 0 0 0-1.193 1.54v41.817c0 .878.714 1.593 1.593 1.593h11.088c.877 0 1.591-.715 1.591-1.593V61.833a1.58 1.58 0 0 0-.618-1.259 1.576 1.576 0 0 0-.97-.334ZM47.216 111.028h-11.09a4.504 4.504 0 0 1-4.498-4.499V72.626a4.5 4.5 0 0 1 3.367-4.355l11.09-2.879a4.467 4.467 0 0 1 3.882.795 4.467 4.467 0 0 1 1.747 3.56v36.782a4.502 4.502 0 0 1-4.498 4.499Zm.004-42.873c-.134 0-.27.017-.404.052l-11.089 2.878a1.594 1.594 0 0 0-1.193 1.541v33.903c0 .879.715 1.593 1.593 1.593h11.089c.878 0 1.592-.714 1.592-1.593V69.747a1.58 1.58 0 0 0-.618-1.259 1.575 1.575 0 0 0-.97-.333Z"
            fill="#fff"
        />
        <path
            d="M33.081 22.956a1.453 1.453 0 0 1-.365-2.86L109.938.047a1.453 1.453 0 1 1 .729 2.813l-77.22 20.047a1.476 1.476 0 0 1-.366.048ZM21.649 59.156a1.453 1.453 0 0 1-.365-2.86l77.222-20.047a1.454 1.454 0 0 1 .729 2.813l-77.22 20.046a1.4 1.4 0 0 1-.366.048ZM10.165 59.945H7.263v-2.902a1.454 1.454 0 0 0-2.907 0v2.902H1.453a1.453 1.453 0 1 0 0 2.906h2.903v2.903a1.454 1.454 0 0 0 2.907 0v-2.903h2.902a1.454 1.454 0 0 0 0-2.906ZM17.159 31.937a5.909 5.909 0 0 1-4.205-1.74 5.908 5.908 0 0 1-1.742-4.204c0-1.588.618-3.08 1.742-4.204a5.907 5.907 0 0 1 4.205-1.741c1.588 0 3.08.618 4.202 1.74a5.902 5.902 0 0 1 1.742 4.205c0 1.588-.618 3.08-1.742 4.204a5.91 5.91 0 0 1-4.202 1.74Zm0-8.981c-.778 0-1.556.296-2.148.888a3.02 3.02 0 0 0-.89 2.149c0 .812.317 1.574.89 2.149a3.041 3.041 0 0 0 4.296 0 3.02 3.02 0 0 0 .89-2.15 3.033 3.033 0 0 0-3.038-3.037Z"
            fill="#7D8FC7"
        />
    </svg>
)

export default DataScienceLogo
